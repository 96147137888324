// src/AppRouter.js
import React, { useContext } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import HomePage from "./components/pages/HomePage";
import AuthPage from "./components/pages/AuthPage";
import ContactPage from "./components/pages/ContactPage";
import ProfilePage from "./components/pages/ProfilePage";
import { AuthContext } from "./contexts/AuthContext";
import { models } from "./models";
import TableData from "./components/table";

const ProtectedRoute = ({ component: Component, ...rest }) => {
  const { isAuthenticated } = useContext(AuthContext);
  console.log("isAuthenticated", isAuthenticated); // Debugging purpose: check if user is authenticated or not
  return isAuthenticated ? (
    <Component {...rest} />
  ) : (
    <Navigate to="/auth" replace />
  );
};

const AppRouter = () => {
  return (
    <Router>
      <Routes>
        <Route
          exact
          path="/"
          element={<ProtectedRoute component={HomePage} />}
        />
        <Route path="/auth" element={<AuthPage />} />
        <Route path="/contact" element={<ContactPage />} />
        <Route
          path="/profile"
          element={<ProtectedRoute component={ProfilePage} />}
        />
        {Object.keys(models).map((moduleName) => {
          const moduleConfig = models[moduleName];
          return (
            <Route
              key={moduleConfig.plural}
              path={"/list/" + moduleConfig.plural}
              element={<TableData module={moduleConfig.single} />}
            />
          );
        })}
      </Routes>
    </Router>
  );
};

export default AppRouter;
