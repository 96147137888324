// src/config/companyConfig.js
export const companyConfig = {
  single: "company",
  plural: "companies",
  label: "Entreprises",
  path: "/company",
  icon: "FaBuilding",
  displayColumns: ["id", "name", "email", "role"],
  excludeColumns: ["password", "createdAt", "updatedAt"],
  /*
  fields: [
    { name: "code", label: "Code", type: "text" },
    { name: "logo", label: "Logo", type: "text" },
    { name: "raisonSociale", label: "Raison Sociale", type: "text" },
    { name: "siret", label: "SIRET", type: "text" },
    { name: "rcs", label: "RCS", type: "text" },
    {
      name: "typeGeneral",
      label: "Type Général",
      type: "select",
      options: [
        { value: "Agent", label: "Agent" },
        { value: "Aucun", label: "Aucun" },
        { value: "Autre", label: "Autre" },
        { value: "Courtier", label: "Courtier" },
        { value: "Mandataire", label: "Mandataire" },
        { value: "Salarié", label: "Salarié" },
      ],
    },
    { name: "orias", label: "Orias", type: "text" },
    { name: "oriasVerifie", label: "Orias Verifié", type: "checkbox" },
    { name: "dateDebutOrias", label: "Date Début Orias", type: "date" },
    { name: "dateFinOrias", label: "Date Fin Orias", type: "date" },
    { name: "informationContact", label: "Information Contact", type: "text" },
    { name: "telephone", label: "Téléphone", type: "text" },
    { name: "mail", label: "Mail", type: "text" },
    { name: "adresse", label: "Adresse", type: "text" },
    { name: "complement", label: "Complément", type: "text" },
    { name: "codePostal", label: "Code Postal", type: "text" },
    { name: "ville", label: "Ville", type: "text" },
    { name: "pays", label: "Pays", type: "text" },
    { name: "divers1", label: "Divers 1", type: "text" },
    { name: "divers2", label: "Divers 2", type: "text" },
    { name: "divers3", label: "Divers 3", type: "text" },
    { name: "divers4", label: "Divers 4", type: "text" },
    { name: "divers5", label: "Divers 5", type: "text" },
    { name: "divers6", label: "Divers 6", type: "text" },
    { name: "divers7", label: "Divers 7", type: "text" },
    { name: "divers8", label: "Divers 8", type: "text" },
    { name: "divers9", label: "Divers 9", type: "text" },
    { name: "divers10", label: "Divers 10", type: "text" },
    { name: "divers11", label: "Divers 11", type: "text" },
    { name: "divers12", label: "Divers 12", type: "text" },
    { name: "divers13", label: "Divers 13", type: "text" },
    { name: "divers14", label: "Divers 14", type: "text" },
    { name: "divers15", label: "Divers 15", type: "text" },
    { name: "divers16", label: "Divers 16", type: "text" },
    { name: "divers17", label: "Divers 17", type: "text" },
    { name: "divers18", label: "Divers 18", type: "text" },
    { name: "divers19", label: "Divers 19", type: "text" },
    { name: "divers20", label: "Divers 20", type: "text" },
  ],
  columns: [
    { key: "code", title: "Code" },
    { key: "raisonSociale", title: "Raison Sociale" },
    { key: "siret", title: "SIRET" },
    { key: "typeGeneral", title: "Type Général" },
    { key: "orias", title: "Orias" },
    { key: "oriasVerifie", title: "Orias Verifié" },
    { key: "dateDebutOrias", title: "Date Début Orias" },
    { key: "dateFinOrias", title: "Date Fin Orias" },
    { key: "telephone", title: "Téléphone" },
    { key: "mail", title: "Mail" },
    { key: "adresse", title: "Adresse" },
    { key: "codePostal", title: "Code Postal" },
    { key: "ville", title: "Ville" },
    { key: "pays", title: "Pays" },
  ], */
};
