import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { signOut } from "firebase/auth";
import { auth } from "./../firebase";
import { FaBars, FaUserCircle, FaHome, FaEnvelope } from "react-icons/fa";
import { models } from "../models";

const Layout = ({ children }) => {
  const navigate = useNavigate();
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const handleSignOut = () => {
    signOut(auth)
      .then(() => {
        navigate("/auth"); // Redirect to the login page after sign-out
      })
      .catch((error) => {
        console.error("Error signing out: ", error);
      });
  };

  return (
    <div className="flex h-screen bg-gray-100">
      {/* Sidebar */}
      <div
        className={`bg-white shadow-md ${
          sidebarOpen ? "w-64" : "w-16"
        } transition-width duration-200`}
      >
        <div className="flex items-center justify-between h-16 p-4">
          <FaBars
            className="cursor-pointer text-xl"
            onClick={() => setSidebarOpen(!sidebarOpen)}
          />
          {sidebarOpen && (
            <span className="ml-2 text-xl font-semibold">Ascrib</span>
          )}
        </div>
        <nav className="flex flex-col p-4">
          <Link
            to="/"
            className="mb-4 text-gray-700 hover:text-blue-500  flex items-center"
          >
            <FaHome className="text-xl mr-2" />
            {sidebarOpen && <span>Home</span>}
          </Link>
          {Object.keys(models).map((key) => (
            <Link
              to={"/list/" + models[key].plural}
              className="mb-4 text-gray-700 hover:text-blue-500 flex items-center"
            >
              {sidebarOpen && <span>{models[key].label}</span>}
            </Link>
          ))}
          <Link
            to="/contact"
            className="text-gray-700 hover:text-blue-500 flex items-center"
          >
            <FaEnvelope className="text-xl mr-2" />
            {sidebarOpen && <span>Contact Us</span>}
          </Link>
        </nav>
      </div>
      {/* Main Content */}
      <div className="flex-1 flex flex-col">
        {/* Header */}
        <header className="bg-white shadow-md flex items-center justify-between h-16 px-4">
          <h1 className="text-xl font-semibold">Gestion</h1>
          <div className="relative">
            <FaUserCircle
              className="text-2xl cursor-pointer"
              onClick={() => setDropdownOpen(!dropdownOpen)}
            />
            {dropdownOpen && (
              <div className="absolute right-0 mt-2 w-48 bg-white shadow-lg rounded-md">
                <Link
                  to="/profile"
                  className="block px-4 py-2 text-gray-700 hover:bg-gray-100"
                >
                  Profile
                </Link>
                <button
                  className="w-full text-left px-4 py-2 text-gray-700 hover:bg-gray-100"
                  onClick={handleSignOut}
                >
                  Sign Out
                </button>
              </div>
            )}
          </div>
        </header>
        {/* Main Content Area */}
        <main className="flex-1 p-4 overflow-y-auto">{children}</main>
      </div>
    </div>
  );
};

export default Layout;
