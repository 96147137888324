import React, { useMemo, useState, useEffect, useCallback } from "react";
import { useTable, usePagination, useFilters, useSortBy } from "react-table";
import { getItems, deleteItem } from "../services/api";
import Layout from "./layout";
import styled from "styled-components";
import { Button, Input, Select, Tooltip, message, Spin } from "antd";
import { EditOutlined, DeleteOutlined } from "@ant-design/icons";
import { getConfigForModule } from "./../models";

const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
  th, td {
    padding: 12px;
    border: 1px solid #ddd;
  }
  th {
    background-color: #f2f2f2;
    font-weight: bold;
  }
`;

const PaginationWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
`;

const DataTable = ({ module }) => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [pageCount, setPageCount] = useState(0);
  const [moduleConfig, setModuleConfig] = useState(null);

  useEffect(() => {
    const loadConfig = async () => {
      try {
        const config = await getConfigForModule(module);
        setModuleConfig(config);
      } catch (error) {
        console.error(`Error loading config for module ${module}:`, error);
        message.error(`Failed to load configuration for ${module}`);
      }
    };
    loadConfig();
  }, [module]);

  const fetchData = useCallback(
    async ({ pageIndex, pageSize, filters, sortBy }) => {
      if (!moduleConfig) return;
      setLoading(true);
      try {
        const response = await getItems(moduleConfig.plural, {
          pageIndex,
          pageSize,
          filters,
          sortBy,
        });
        setData(response.data || []);
        setPageCount(response.pageCount || 0);
      } catch (error) {
        console.error("Error fetching data:", error);
        message.error("Failed to fetch data");
        setData([]);
        setPageCount(0);
      } finally {
        setLoading(false);
      }
    },
    [moduleConfig]
  );

  const columns = useMemo(() => {
    if (!moduleConfig) return [];

    const {
      displayColumns,
      excludeColumns = [],
      customRenderers = {},
    } = moduleConfig;

    const sampleData = data && data.length > 0 ? data[0] : {};
    const allKeys = Object.keys(sampleData).filter(
      (key) => !excludeColumns.includes(key)
    );
    const columnsToDisplay = displayColumns || allKeys;

    return columnsToDisplay.map((key) => ({
      Header:
        key.charAt(0).toUpperCase() +
        key
          .slice(1)
          .replace(/([A-Z])/g, " $1")
          .trim(),
      accessor: key,
      Cell: customRenderers[key] || (({ value }) => String(value ?? "")),
      Filter: ({ column: { filterValue, setFilter } }) => (
        <Input
          value={filterValue || ""}
          onChange={(e) => setFilter(e.target.value)}
          placeholder={`Search ${key}`}
        />
      ),
    }));
  }, [data, moduleConfig]);

  const handleDelete = async (id) => {
    try {
      await deleteItem(moduleConfig.plural, id);
      fetchData({ pageIndex, pageSize, filters, sortBy });
      message.success("Item deleted successfully");
    } catch (error) {
      console.error("Error deleting item:", error);
      message.error("Failed to delete item");
    }
  };

  const actionColumn = {
    Header: "Actions",
    id: "actions",
    Cell: ({ row }) => (
      <>
        <Tooltip title="Edit">
          <Button
            icon={<EditOutlined />}
            onClick={() => {
              console.log("Edit item:", row.original);
            }}
          />
        </Tooltip>
        <Tooltip title="Delete">
          <Button
            icon={<DeleteOutlined />}
            onClick={() => {
              if (
                window.confirm("Are you sure you want to delete this item?")
              ) {
                handleDelete(row.original.id);
              }
            }}
            style={{ marginLeft: 8 }}
          />
        </Tooltip>
      </>
    ),
  };

  const allColumns = useMemo(() => [...columns, actionColumn], [columns]);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount: controlledPageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize, filters, sortBy },
  } = useTable(
    {
      columns: allColumns,
      data,
      initialState: { pageIndex: 0, pageSize: 10 },
      manualPagination: true,
      manualFilters: true,
      manualSortBy: true,
      pageCount,
      autoResetPage: false,
      autoResetFilters: false,
      autoResetSortBy: false,
    },
    useFilters,
    useSortBy,
    usePagination
  );

  useEffect(() => {
    if (moduleConfig) {
      fetchData({ pageIndex, pageSize, filters, sortBy });
    }
  }, [fetchData, pageIndex, pageSize, filters, sortBy, moduleConfig]);

  if (!moduleConfig) {
    return <Spin size="large" />;
  }

  return (
    <Layout>
      {loading ? (
        <Spin size="large" />
      ) : (
        <>
          <Table {...getTableProps()}>
            <thead>
              {headerGroups.map((headerGroup) => {
                const { key, ...headerGroupProps } =
                  headerGroup.getHeaderGroupProps();
                return (
                  <tr key={headerGroup.id} {...headerGroupProps}>
                    {headerGroup.headers.map((column) => {
                      const { key, ...columnProps } = column.getHeaderProps(
                        column.getSortByToggleProps()
                      );
                      return (
                        <th key={column.id} {...columnProps}>
                          {column.render("Header")}
                          <span>
                            {column.isSorted
                              ? column.isSortedDesc
                                ? " 🔽"
                                : " 🔼"
                              : ""}
                          </span>
                          <div>
                            {column.canFilter ? column.render("Filter") : null}
                          </div>
                        </th>
                      );
                    })}
                  </tr>
                );
              })}
            </thead>
            <tbody {...getTableBodyProps()}>
              {page.map((row) => {
                prepareRow(row);
                const { key, ...rowProps } = row.getRowProps();
                return (
                  <tr key={row.id} {...rowProps}>
                    {row.cells.map((cell) => {
                      const { key, ...cellProps } = cell.getCellProps();
                      return (
                        <td key={cell.column.id} {...cellProps}>
                          {cell.render("Cell")}
                        </td>
                      );
                    })}
                  </tr>
                );
              })}
            </tbody>
          </Table>
          <PaginationWrapper>
            <Button onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
              {"<<"}
            </Button>
            <Button onClick={() => previousPage()} disabled={!canPreviousPage}>
              {"<"}
            </Button>
            <span>
              Page{" "}
              <strong>
                {pageIndex + 1} of {pageOptions.length}
              </strong>{" "}
            </span>
            <Button onClick={() => nextPage()} disabled={!canNextPage}>
              {">"}
            </Button>
            <Button
              onClick={() => gotoPage(pageCount - 1)}
              disabled={!canNextPage}
            >
              {">>"}
            </Button>
            <Select
              value={pageSize}
              onChange={(value) => setPageSize(Number(value))}
              style={{ width: 120 }}
            >
              {[10, 20, 30, 40, 50].map((pageSize) => (
                <Select.Option key={pageSize} value={pageSize}>
                  Show {pageSize}
                </Select.Option>
              ))}
            </Select>
          </PaginationWrapper>
        </>
      )}
    </Layout>
  );
};

export default DataTable;
