/**
 * AuthProvider component manages authentication state and provides user context.
 * It uses Firebase's onAuthStateChanged and onIdTokenChanged listeners to update the authentication state.
 * If the user is authenticated, it sets a cookie on the server.
 * If there's an error during authentication, it displays an error message.
 * While loading, it displays a loading spinner.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {ReactNode} props.children - The child components to render within the AuthContext.
 * @returns {JSX.Element} - The AuthProvider component.
 */
import React, { createContext, useState, useEffect } from "react";
import { Spin, Alert } from "antd";
import { LoadingOutlined, InfoCircleOutlined } from "@ant-design/icons";
import { onIdTokenChanged, onAuthStateChanged } from "firebase/auth";
import { auth } from "../firebase";
import config from "../config";

// Create the AuthContext
export const AuthContext = createContext();

const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  // Customize the antd spin indicator
  const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

  useEffect(() => {
    // Listen for changes in authentication state
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      setUser(user);
      setIsAuthenticated(!!user);
    });

    // Listen for changes in ID token
    const unsubscribe_cookie = onIdTokenChanged(auth, async (newUser) => {
      setLoading(true);
      if (newUser) {
        try {
          // If the user has changed from null to a non-null user, set the cookie
          // we do that to prevent a promise that blocks the react router function from running in signin
          if (user === newUser) {
            const serverUrl = config.apiUrl;
            const token = await newUser.getIdToken();
            // Set cookie on the server
            await fetch(serverUrl + "/auth/set-cookie", {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
              },
              credentials: "include",
              body: JSON.stringify({ token }),
            });
            setUser(newUser);
          }
          setIsAuthenticated(true);
          setError(null);
        } catch (error) {
          console.error("Error setting cookie:", error);
          setError("Failed to authenticate with server.");
        }
      } else {
        setUser(null);
      }
      setLoading(false);
    });

    // Clean up listeners on unmount
    return () => {
      unsubscribe();
      unsubscribe_cookie();
    };
  }, []);

  // Display loading spinner while loading
  if (loading) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <Spin indicator={antIcon} />
      </div>
    );
  }

  // Display error message if there's an error
  if (error) {
    return (
      <Alert
        message="Authentication Error"
        description={error}
        type="error"
        showIcon
        icon={<InfoCircleOutlined style={{ color: "red" }} />}
        style={{ margin: "20px" }}
      />
    );
  }

  // Provide user context to child components
  return (
    <AuthContext.Provider value={{ user, isAuthenticated }}>
      {children}
    </AuthContext.Provider>
  );
};

export default AuthProvider;
