import React from "react";
import Layout from "./../layout";

const ProfilePage = () => {
  return (
    <Layout>
      <h2 className="text-2xl font-bold mb-4">Profile</h2>
      <div className="bg-white p-4 shadow-md rounded-md">
        <p className="text-lg font-semibold mb-2">User Name</p>
        <p className="text-gray-700 mb-4">user@example.com</p>
        <p className="text-sm text-gray-600">
          Additional profile information can be displayed here.
        </p>
      </div>
    </Layout>
  );
};

export default ProfilePage;
