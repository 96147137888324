import React, { useState } from "react";

const ContactPage = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [submitted, setSubmitted] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();
    // Add your form submission logic here
    setSubmitted(true);
  };

  return (
    <div className="min-h-screen flex flex-col items-center justify-center bg-gray-100">
      <div className="w-full max-w-md p-8 bg-white shadow-md rounded-md">
        <h1 className="text-2xl font-bold mb-4 text-[#1e0e4b]">Contact Us</h1>
        <p className="text-sm font-normal mb-4 text-gray-700">
          We'd love to hear from you! Please fill out the form below and we'll
          get in touch with you as soon as possible.
        </p>
        {submitted ? (
          <div className="text-green-500 text-center">
            Thank you for your message! We'll be in touch soon.
          </div>
        ) : (
          <form className="flex flex-col gap-4" onSubmit={handleSubmit}>
            <div className="block relative">
              <label
                htmlFor="name"
                className="block text-gray-600 cursor-text text-sm leading-[140%] font-normal mb-2"
              >
                Name
              </label>
              <input
                type="text"
                id="name"
                className="rounded border border-gray-200 text-sm w-full font-normal leading-[18px] text-black tracking-[0px] appearance-none block h-11 m-0 p-[11px] focus:ring-2 ring-offset-2 ring-gray-900 outline-0"
                value={name}
                onChange={(e) => setName(e.target.value)}
                required
              />
            </div>
            <div className="block relative">
              <label
                htmlFor="email"
                className="block text-gray-600 cursor-text text-sm leading-[140%] font-normal mb-2"
              >
                Email
              </label>
              <input
                type="email"
                id="email"
                className="rounded border border-gray-200 text-sm w-full font-normal leading-[18px] text-black tracking-[0px] appearance-none block h-11 m-0 p-[11px] focus:ring-2 ring-offset-2 ring-gray-900 outline-0"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
              />
            </div>
            <div className="block relative">
              <label
                htmlFor="message"
                className="block text-gray-600 cursor-text text-sm leading-[140%] font-normal mb-2"
              >
                Message
              </label>
              <textarea
                id="message"
                className="rounded border border-gray-200 text-sm w-full font-normal leading-[18px] text-black tracking-[0px] appearance-none block h-24 m-0 p-[11px] focus:ring-2 ring-offset-2 ring-gray-900 outline-0"
                value={message}
                onChange={(e) => setMessage(e.target.value)}
                required
              />
            </div>
            <button
              type="submit"
              className="bg-[#7747ff] w-max m-auto px-6 py-2 rounded text-white text-sm font-normal"
            >
              Submit
            </button>
          </form>
        )}
      </div>
    </div>
  );
};

export default ContactPage;
