// src/config/index.js
import { companyConfig } from "./comapny.config";
// import other module configs

export const models = {
  company: companyConfig,
  // other module configs
};

export const getConfigForModule = (moduleName) => {
  return models[moduleName];
};
