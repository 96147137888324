import axios from "axios";
import qs from "qs";
import config from "../config";

const apiClient = axios.create({
  baseURL: config.apiUrl,
  headers: {
    "Content-Type": "application/json",
  },
  withCredentials: true,
});

// Add request interceptor
apiClient.interceptors.request.use((request) => {
  console.log("Starting Request", JSON.stringify(request, null, 2));
  return request;
});

// Add response interceptor
apiClient.interceptors.response.use(
  (response) => {
    console.log("Response:", JSON.stringify(response, null, 2));
    return response;
  },
  (error) => {
    console.log(
      "Response Error:",
      error.response ? JSON.stringify(error.response, null, 2) : error.message
    );
    return Promise.reject(error);
  }
);

// Helper function to handle API errors
const handleApiError = (error, operation, module) => {
  console.error(`Error ${operation} ${module}:`, error);
  throw error;
};

export const getItems = async (
  module,
  { pageIndex = 0, pageSize = 10, filters = {}, sortBy = [] }
) => {
  try {
    const queryParams = {
      pageIndex,
      pageSize,
      ...filters,
      sortBy: sortBy
        .map((sort) => `${sort.id}:${sort.desc ? "desc" : "asc"}`)
        .join(","),
    };

    const url = `/${module}?${qs.stringify(queryParams, {
      arrayFormat: "repeat",
      skipNulls: true,
    })}`;

    const response = await apiClient.get(url);
    console.log("API response:", response);
    return {
      data: response.data,
      pageCount: Math.ceil(response.data.length / pageSize),
      totalCount: response.data.length,
    };
  } catch (error) {
    handleApiError(error, "fetching", module);
  }
};

export const getItemById = async (module, id) => {
  try {
    const response = await apiClient.get(`/${module}/${id}`);
    return response.data;
  } catch (error) {
    handleApiError(error, "fetching", `${module} with id ${id}`);
  }
};

export const createItem = async (module, data) => {
  try {
    const response = await apiClient.post(`/${module}`, data);
    return response.data;
  } catch (error) {
    handleApiError(error, "creating", module);
  }
};

export const updateItem = async (module, id, data) => {
  try {
    const response = await apiClient.put(`/${module}/${id}`, data);
    return response.data;
  } catch (error) {
    handleApiError(error, "updating", `${module} with id ${id}`);
  }
};

export const deleteItem = async (module, id) => {
  try {
    const response = await apiClient.delete(`/${module}/${id}`);
    return response.data;
  } catch (error) {
    handleApiError(error, "deleting", `${module} with id ${id}`);
  }
};

// New function to handle bulk operations
export const bulkOperation = async (module, operation, ids) => {
  try {
    const response = await apiClient.post(`/${module}/bulk`, {
      operation,
      ids,
    });
    return response.data;
  } catch (error) {
    handleApiError(error, `bulk ${operation}`, module);
  }
};

// New function to handle file uploads
export const uploadFile = async (module, id, file, onUploadProgress) => {
  try {
    const formData = new FormData();
    formData.append("file", file);

    const response = await apiClient.post(`/${module}/${id}/upload`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
      onUploadProgress,
    });

    return response.data;
  } catch (error) {
    handleApiError(error, "uploading file for", `${module} with id ${id}`);
  }
};
