import React, { useState, useEffect, useRef, useContext } from "react";
import { RecaptchaVerifier, signInWithPhoneNumber } from "firebase/auth";
import { useNavigate } from "react-router-dom";

import { auth } from "../../firebase";
import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";
import loginImage from "./../../assets/pexels-canvastudio-3194524.jpg"; // Replace with the path to your image
import config from "../../config";
import { AuthContext } from "../../contexts/AuthContext";

const AuthPage = () => {
  const { isAthenticated } = useContext(AuthContext);
  const [phone, setPhone] = useState("");
  const [code, setCode] = useState("");
  const [error, setError] = useState("");
  const [verifying, setVerifying] = useState(false);
  const [step, setStep] = useState("phone"); // State to manage steps
  const recaptchaVerifierRef = useRef(null);
  const confirmationResultRef = useRef(null);
  const navigate = useNavigate();

  const goToHome = async () => {
    navigate("/");
  };

  useEffect(() => {
    recaptchaVerifierRef.current = new RecaptchaVerifier(
      auth,
      "recaptcha-container",
      {
        size: "invisible",
        callback: (response) => {
          console.log("Captcha resolved, response:", response);
        },
      },
      auth
    );
    recaptchaVerifierRef.current.render().catch((error) => {
      setError(
        "Recaptcha failed to load. Please check your internet connection and try again."
      );
      console.error("Error rendering Recaptcha:", error);
    });
  }, []);

  const handleSendCode = () => {
    setVerifying(true);
    setError("");

    signInWithPhoneNumber(auth, phone, recaptchaVerifierRef.current)
      .then((confirmationResult) => {
        confirmationResultRef.current = confirmationResult;
        setVerifying(false);
        setStep("code"); // Move to the next step
      })
      .catch((error) => {
        console.error("Error sending verification code:", error, phone);
        setError("Failed to send verification code. Please try again.");
        setVerifying(false);
      });
  };

  const handleVerifyCode = () => {
    setVerifying(true);
    setError("");
    confirmationResultRef.current
      .confirm(code)
      .then((result) => {
        console.log("Verification successful:", result);
        // Get the Firebase ID token
        const serverUrl = config.apiUrl;
        result.user.getIdToken().then((idToken) => {
          console.log(serverUrl + "/auth/set-cookie");
          // Send the token to the backend to set it as an HTTP-only cookie
          fetch(serverUrl + "/auth/set-cookie", {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              "Authorization": "Bearer " + idToken,
            },
            body: JSON.stringify({ token: idToken }),
            credentials: "include", // Ensure the cookie is set
          })
            .then((response) => {
              if (!response.ok) {
                throw new Error("Failed to set the cookie");
              }
              console.log("Cookie set successfully");
              /*   const nav = navigate("/", {
                replace: true,
              }); */ // Redirect to the profile page after verification

              setVerifying(false);
              goToHome();
            })
            .catch((error) => {
              console.error("Error setting the cookie:", error);
              setError(
                "Failed to set the authentication cookie. Please try again."
              );
              setVerifying(false);
              console.error("Error setting the cookie:", error);
            });
        });
      })
      .catch((error) => {
        setError("Invalid verification code. Please try again.");
        setVerifying(false);
      });
  };

  return (
    <div className="h-screen flex">
      <div className="w-1/2 flex flex-col justify-center items-center p-8 bg-white">
        <div className="max-w-md w-full">
          <div className="text-2xl font-bold mb-2 text-[#1e0e4b] text-center">
            Welcome to <span className="text-[#7747ff]">Assurscribe Main</span>
          </div>
          <div className="text-sm font-normal mb-4 text-center text-[#1e0e4b]">
            Log in to your account
          </div>
          <div id="recaptcha-container" aria-hidden="true"></div>
          {step === "phone" && (
            <form className="flex flex-col gap-3 animate-fade-in">
              <div className="block relative">
                <label
                  htmlFor="phone"
                  className="block text-gray-600 cursor-text text-sm leading-[140%] font-normal mb-2"
                >
                  Phone Number
                </label>
                <PhoneInput
                  international
                  defaultCountry="FR"
                  value={phone}
                  onChange={setPhone}
                  placeholder="Enter phone number"
                  disabled={verifying}
                  className="rounded border border-gray-200 text-sm w-full font-normal leading-[18px] text-black tracking-[0px] appearance-none block h-11 m-0 p-[11px] focus:ring-2 ring-offset-2  ring-gray-900 outline-0"
                />
              </div>
              <button
                type="button"
                className="bg-[#7747ff] w-max m-auto px-6 py-2 rounded text-white text-sm font-normal"
                onClick={handleSendCode}
                disabled={verifying}
                aria-disabled={verifying}
              >
                Send Code
              </button>
            </form>
          )}
          {step === "code" && (
            <form className="flex flex-col gap-3 animate-fade-in">
              <div className="block relative">
                <label
                  htmlFor="code"
                  className="block text-gray-600 cursor-text text-sm leading-[140%] font-normal mb-2"
                >
                  Verification Code
                </label>
                <input
                  type="text"
                  id="code"
                  className="rounded border border-gray-200 text-sm w-full font-normal leading-[18px] text-black tracking-[0px] appearance-none block h-11 m-0 p-[11px] focus:ring-2 ring-offset-2 ring-gray-900 outline-0"
                  value={code}
                  onChange={(e) => setCode(e.target.value)}
                  placeholder="Enter verification code"
                  disabled={verifying}
                  aria-label="Verification Code"
                />
              </div>
              <button
                type="button"
                className="bg-[#7747ff] w-max m-auto px-6 py-2 rounded text-white text-sm font-normal"
                onClick={handleVerifyCode}
                disabled={verifying || !confirmationResultRef.current}
                aria-disabled={verifying || !confirmationResultRef.current}
              >
                Verify Code
              </button>
            </form>
          )}
          {error && (
            <p className="text-red-500 text-sm text-center mt-2" role="alert">
              {error}
            </p>
          )}

          <div className="text-sm text-center mt-[1.6rem]">
            Don’t have an account yet?{" "}
            <a className="text-sm text-[#7747ff]" href="/contact">
              Contactez-nous!
            </a>
          </div>
        </div>
      </div>
      <div className="w-1/2">
        <img
          src={loginImage}
          alt="Login"
          className="object-cover w-full h-full"
        />
      </div>
    </div>
  );
};

export default AuthPage;
