const domains = {
  production: {
    base: "ascrib.xyz",
    api: "ascrib.rest",
  },
  staging: {
    base: "ascrib-staging.xyz",
    api: "staging.ascrib.rest",
  },
  development: {
    base: "localhost:3000",
    api: "localhost:3000",
  },
};

const env = process.env.REACT_APP_ENV || "development";
console.log("env", env);
const { base, api } = domains[env];

const getSubdomain = () => {
  const hostname = window.location.hostname;
  const parts = hostname.split(".");
  return parts.length > 2 ? parts[0] : "";
};

const apiUrl = `http${api.startsWith("localhost") ? "" : "s"}://${api}`;
const subdomain = getSubdomain();
const baseUrl = `http${base.startsWith("localhost") ? "" : "s"}://${
  subdomain ? `${subdomain}.` : ""
}${base}`;

console.log("baseUrl", baseUrl);
const config = {
  env,
  apiUrl,
  baseUrl,
  getSubdomain,
};

export default config;
