// src/firebase.js
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";

const firebaseConfig = {
  apiKey: "AIzaSyAxQrRfxwfjNBEECtzdEt5H3kdaHW2u6Ls",
  authDomain: "qualidev-9675f.firebaseapp.com",
  projectId: "qualidev-9675f",
  storageBucket: "qualidev-9675f.appspot.com",
  messagingSenderId: "617470387323",
  appId: "1:617470387323:web:9d68ef23475f59832c2ede",
  measurementId: "G-GVCCCSNT78",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Get the Firebase Auth instance
const auth = getAuth(app);

export { auth, app };
