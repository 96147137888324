import React from "react";
import { Link } from "react-router-dom";
import Layout from "../layout";

const HomePage = () => {
  return (
    <Layout>
      <div className="min-h-screen flex flex-col items-center justify-center bg-gray-100">
        <div className="w-full max-w-4xl text-center p-8 bg-white shadow-md rounded-md">
          <h1 className="text-4xl font-bold mb-4 text-[#1e0e4b]">
            Welcome to Our App
          </h1>
          <p className="text-lg font-normal mb-4 text-gray-700">
            Your gateway to the best user experience.
          </p>
          <div className="mb-4"></div>
          <div className="flex justify-center space-x-4">
            <Link
              to="/auth"
              className="bg-[#7747ff] text-white px-6 py-2 rounded text-sm font-normal"
            >
              Get Started
            </Link>
            <Link
              to="/contact"
              className="bg-gray-200 text-black px-6 py-2 rounded text-sm font-normal"
            >
              Contact Us
            </Link>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default HomePage;
